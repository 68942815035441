<template>
  <div class="height-100 max_box">
    <div class="audio-box">
      <audio
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-e2cb1aa8.mp3"
        loop="loop"
        ref="bgAudio"
      ></audio>
      <audio
        @canplay="getDuration(1)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-bfcbc657.mp3"
        ref="audio1"
      ></audio>
      <audio
        @canplay="getDuration(2)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-0b1757a1.mp3"
        ref="audio2"
      ></audio>
      <audio
        @canplay="getDuration(3)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-d715319f.mp3"
        ref="audio3"
      ></audio>
      <audio
        @canplay="getDuration(4)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-3289dc6f.mp3"
        ref="audio4"
      ></audio>
      <audio
        @canplay="getDuration(5)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-f4665007.mp3"
        ref="audio5"
      ></audio>
      <audio
        @canplay="getDuration(6)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-1cd2770f.mp3"
        ref="audio6"
      ></audio>
      <audio
        @canplay="getDuration(7)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-6c708534.mp3"
        ref="audio7"
      ></audio>
      <audio
        @canplay="getDuration(8)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-4a48642a.mp3"
        ref="audio8"
      ></audio>
      <audio
        @canplay="getDuration(9)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-a044dbe7.mp3"
        ref="audio9"
      ></audio>
      <audio
        @canplay="getDuration(10)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-14531da4.mp3"
        ref="audio10"
      ></audio>
      <audio
        @canplay="getDuration(11)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-1ef09c7f.mp3"
        ref="audio11"
      ></audio>
    </div>
    <div class="animate__animated animate__fadeIn duration" id="card1">
      <div class="title">
        <div class="img_box">
          <img src="../../assets/img/zh_img1.png" alt="" />
        </div>
        <div class="title_name">
          <p>警报拉响！</p>
          <p>热点板块多股冲高回落</p>
          <p>沪指痛失3600！</p>
        </div>
        <div class="daily_back">每日收盘回顾</div>
        <div class="title_time">2021.11.8</div>
        <div class="btn_start" id="btnStart" @click="startPlay">
          {{ isFullLoaded ? "开始解读" : "加载中..." }}
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/zhlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        主题简况
        <span style="margin-right: 5px"></span>
        <span></span>
      </div>
      <div class="content_box">
        <div class="title_card">上证指数</div>
        <div class="chart1_dec">
          <span>上证指数</span>
          <span>MA5 {{ ma5 }}</span>
          <span>MA10 {{ ma10 }}</span>
          <span>MA20 {{ ma20 }}</span>
        </div>
        <div class="d3_chart d3_k" id="d3K"></div>
        <div class="date_style">
          <span>{{ startDate }}</span
          ><span>{{ endDate }}</span>
        </div>
        <p>
          10月26日，A股三大指数震荡走低，上证指数、深证成指窄幅波动并翻绿，创业板指高开近1%后逐步回落。两市个股跌多涨少，约2800股飘绿，市场赚钱效应较差。
        </p>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/zhlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        主题简况
        <span style="margin-right: 5px"></span>
        <span></span>
      </div>
      <div class="content_box">
        <p class="bg_red">
          受特斯拉股价创新高刺激，锂电池表现活跃，消费电子午后异动拉升，储能、电力、半导体等表现活跃，但游戏、国产软件、房地产、券商等纷纷走低。
        </p>
        <p>
          截至收盘沪指跌0.34%，报收3597.64点，成交额为4652亿；深成指跌0.30%，报收14552.82点，成交额为6172亿，创业板指跌0.33%，报收3327.54点，成交额为2331亿。
        </p>
        <div class="title_card">沪深成指</div>
        <div class="d3_chart d3_volume" id="d3Volume"></div>
        <div class="date_style">
          <span>{{ startDate }}</span
          ><span>{{ endDate }}</span>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/zhlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card4"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        资金流向
        <span style="margin-right: 5px"></span>
        <span></span>
      </div>
      <div class="content_box">
        <p class="bg_red">
          资金方面，北向资金今天净流入39.9亿元，今日主力资金净流入9.8亿元。
        </p>
        <p class="bg_red">
          行业资金流向方面，今日有14个行业主力资金净流入，电子行业主力资金净流入规模居首，全天净流入资金30.7亿元，其次是公用事业行业，净流入资金为19.2亿元。
        </p>
        <p>
          主力资金净流出的有14个行业，非银金融行业主力资金净流出规模居首，全天净流出资金27.0亿元，其次是国防军工行业，净流出资金为13.5亿元。
        </p>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/zhlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card5"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        行业涨跌
        <span style="margin-right: 5px"></span>
        <span></span>
      </div>
      <div class="content_box">
        <div class="chart1_dec">
          行业涨跌方面，申万所属的28个一级行业中，今日涨幅居前的行业为电子、有色金属、综合，涨幅为1.14%、0.55%、0.54%；跌幅居前的行业为家用电器、房地产、农林牧渔，跌幅为1.72%、1.72%、1.38%。
        </div>
        <div class="chart_box">
          <div class="chart_name">行业涨跌幅及领涨/跌股详细信息表</div>
          <div class="img_box">
            <img src="../../assets/img/zh_table1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/zhlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card6"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        个股解析
        <span style="margin-right: 5px"></span>
        <span></span>
      </div>
      <div class="content_box">
        <ul>
          <li>
            <div class="top">
              <div class="img_box">
                <img src="../../assets/img/line_left.png" alt="" />
              </div>
              <div>A股动向</div>
              <div class="img_box">
                <img src="../../assets/img/line_right.png" alt="" />
              </div>
            </div>
            <div class="bg_red">
              个股方面（包括ST不包括未开板新股），A股上涨家数1600家，下跌2784家，波动幅度跑赢大盘的有1866家，挣钱效应较差。A股涨停44家，比上一交易日减少46.3%；跌停18家，比上一交易日增加100.0%。
            </div>
          </li>
          <li>
            <div class="top">
              <div class="img_box">
                <img src="../../assets/img/line_left.png" alt="" />
              </div>
              <span>涨跌幅</span>
              <div class="img_box">
                <img src="../../assets/img/line_right.png" alt="" />
              </div>
            </div>
            <div>
              从个股涨跌幅来看，长信科技涨跌幅13.7%，位居大额成交额股票涨跌幅榜首，节能风电、方大炭素紧随其后，涨跌幅分别为10.1%和10.0%。
            </div>
          </li>
          <li>
            <div class="top">
              <div class="img_box">
                <img src="../../assets/img/line_left.png" alt="" />
              </div>
              <span>个股资金</span>
              <div class="img_box">
                <img src="../../assets/img/line_right.png" alt="" />
              </div>
            </div>
            <div class="bg_red">
              个股资金流向方面，共有46只个股主力资金净流入超亿元。节能风电主力资金净流入11.17亿元，净流入资金居首；立讯精密、方大炭素紧随其后，全天净流入资金分别为7.07亿元、5.77亿元。
            </div>
          </li>
        </ul>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/zhlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card7"
    >
      <div class="content" v-show="contentShow">
        <div class="top_title">
          <span style="margin-right: 5px"></span>
          <span></span>
          股票涨跌榜
          <span style="margin-right: 5px"></span>
          <span></span>
        </div>
        <div class="content_box">
          <div class="chart1_dec">
            主力资金流出个股中，宁德时代主力资
            金净流出9.44亿元，净流出资金最多，
            其次是中国平安、恒瑞医药，净流出资 金分别为8.20亿元、7.61亿元。
          </div>
          <div class="chart_box">
            <div class="chart_name">大额成交额股票涨跌幅排行榜</div>
            <div class="img_box">
              <img src="../../assets/img/zh_table2.png" alt="" />
            </div>
          </div>
        </div>

        <div class="logo-box">
          <img src="../../assets/img/zhlogo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card8"
    >
      <div class="content" v-show="contentShow">
        <div class="top_title">
          <span style="margin-right: 5px"></span>
          <span></span>
          今日掘金
          <span style="margin-right: 5px"></span>
          <span></span>
        </div>
        <div class="content_box">
          <p>
            创业板方面，创业板共16股涨幅超10%，科新机电位居创业板涨幅榜首，涨幅为20.0%。英力股份、隆利科技紧随其后，涨幅分别为20.0%和20.0%。
          </p>
          <p>
            整体来看，培育钻石、CRO、医美、教育等
            板块低迷。总体上个股呈现普跌态势，炸板
            率近50%，近2800股飘绿，沪深两市成交额
            连续第三个交易日突破万亿元。<span class="bg_red"
              >开盘破发的 科创板新股中科微至收跌超12%，创业板新
              股华润材料尾盘大幅上扬，涨幅达81.63%。</span
            >
          </p>
          <p class="bg_red">
            今日歌尔股份(+4.3%)、立讯精密(+4.1%)、汇川技术(+3.9%)、北方稀土(+3.6%)、荣盛石化(+3.1%)涨幅居前。
          </p>
          <p>
            创业板方面，创业板共16股涨幅超10%，科新机电位居创业板涨幅榜首，涨幅为0.0%。英力股份、隆利科技紧随其后，涨幅分别为20.0%和20.0%。
          </p>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/zhlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card9"
    >
      <div class="content" v-show="contentShow">
        <div class="top_title">
          <span style="margin-right: 5px"></span>
          <span></span>
          基金重仓大涨股明细表
          <span style="margin-right: 5px"></span>
          <span></span>
        </div>
        <div class="content_box">
          <div class="hand_box" @click="goAd">
            <img src="../../assets/img/zh_hand.png" alt="" />
          </div>
          <div class="img_box">
            <img src="../../assets/img/zh_table3.png" alt="" />
          </div>
        </div>
        <div class="logo-box">
          <img src="../../assets/img/zhlogo.png" alt="" />
        </div>
      </div>
      <div class="ad_page" v-show="adShow">
        <div class="img_box" @click="goBack">
          <img src="../../assets/img/zh_ad.jpg" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card10"
    >
      <div class="content" v-show="contentShow">
        <div class="top_title">
          <span style="margin-right: 5px"></span>
          <span></span>
          机构观点
          <span style="margin-right: 5px"></span>
          <span></span>
        </div>
        <div class="content_box">
          <p class="bg_red">机构: A股发生系统性风险的概率仍较小</p>
          <p>
            广发证券表示，近期，市场普遍担忧全球能源价格快速上涨导致“滞胀”风险，<span
              class="bg_red"
              >但展望后市，供给收缩带来的“类滞胀”较难掣肘流动性政策，A股发生系统性风险的概率仍较小。同时，虽然当前全球经济增长边际放缓，但中国企业盈利仍有韧性。而“类滞胀”时期，业绩相对稳健的消费和经济下行压力下潜在“稳增长”政策预期受益部分低估值板块</span
            >。
          </p>

          <p>
            华西证券表示，<span class="bg_red"
              >海外来看，通胀预期的上行使得投资者对美联储加息预期升温，但资本市场对此预期较充分，造成恐慌性抛售的概率较小。</span
            >国内方面，房地产居民贷款、保供稳价、地方债发行等政策出现边际调整，四季度经济不存在失速风险，叠加年底国内迎来诸多重要会议，政策红利预期下市场风险偏好有望改善，A股仍处于“可为期”。
          </p>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/zhlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card11"
    >
      <div class="content_box">
        <div class="box_title">免责声明</div>
        <p>
          所有展示信息仅供参考，不构成任何投资建议。
          招商银行对信息的准确性、完整性或可靠性、
          及时性不作任何保证，并非作为买卖、认购证
          券或其它金融工具的邀请或保证。投资者据此
          操作，风险自担。投资有风险，入市需谨慎。
        </p>
        <p>招商银行提示投资者请密切关注自身风险承受 能力及投资目标。</p>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/zhlogo.png" alt="" />
      </div>
      <div class="restart" v-show="reStartState" @click="reStart()">
        再看一遍
      </div>
    </div>
    <div class="progess" v-show="progessState">
      <span class="play" @click="stop()" v-show="playState"></span>
      <span class="zanting" @click="play()" v-show="!playState"></span>
      <!-- <span>{{curentTime}}/</span>
      <span class="time">{{totalTime}}</span> -->
      <input
        id="range"
        type="range"
        min="1"
        max="11"
        :value="rangeValue"
        autofocus
        step="1"
        @change="changeRange()"
        class="slider"
      />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { sleep } from "../../utils/common";
import { drawVolume, drawK } from "@/components/kChartNoBorder.js";
export default {
  name: "Template57",
  data() {
    return {
      currentCard: 1,
      lastTimestamp: 0, //记录进入audio播放的时间戳
      isFullLoaded: true, //全部需要预先加载的完成标志
      reStartState: false,
      playState: true,
      adShow: false,
      contentShow: true,
      progessState: false,
      rangeValue: 1,
      seccode: "2020882313",
      secname: "",
      dataK: [],
      ma5: "",
      ma10: "",
      ma20: "",
      startDate: "",
      endDate: "",
    };
  },
  mounted() {
    document.title = "招商银行互动视频";
    this.ajaxKByDate();
    this.ajaxSecname();
  },
  methods: {
    //加载完毕后才可以播放
    getDuration(count) {
      // if (count >= 6) {
      this.isFullLoaded = true;
      // }
    },
    stopAudio() {
      this.$refs["audio" + this.currentCard].pause();
      this.$refs.bgAudio.pause();
    },
    playAudio() {
      this.$refs["audio" + this.currentCard].play();
      this.$refs.bgAudio.play();
    },

    goAd() {
      this.adShow = true;
      this.contentShow = false;
      this.$refs.audio9.pause();
    },
    goBack() {
      this.adShow = false;
      this.contentShow = true;
      this.$refs.audio9.play();
    },
    //根据begin和end date获取日k
    async ajaxKByDate() {
      const url =
        "https://comm.wedengta.com/?s=kline&f=getKLineByDate&req=KLineDateReq&rsp=KLineDateRsp";
      const params = {
        sDtSecCode: this.seccode,
        eKLineType: 4,
        sEndDate: this.$moment().format("YYYYMMDD"),
        iWantnum: 80,
      };
      const data = await this.$axios.post(url, params);
      const _data = JSON.parse(data.content).vKLineDesc.reverse();
      // console.log("_data", _data);
      this.dataK = _data;
    },
    async ajaxSecname() {
      const url =
        "https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp";
      const params = {
        vDtSecCode: [this.seccode],
      };
      const data = await this.$axios.post(url, params);
      const _data = JSON.parse(data.content).vSecSimpleQuote;
      this.secname = _data[0].sSecName;
    },
    renderKLine(fullKData) {
      console.log("fullKData", fullKData);
      // const data = fullKData.slice(frameIndex, frameIndex + 50);
      //用50条数据，算出30条准确的均线数值
      const ave5 = this.getAverageValue(5, fullKData).slice(20, 80);
      const ave10 = this.getAverageValue(10, fullKData).slice(20, 80);
      const ave20 = this.getAverageValue(20, fullKData).slice(20, 80);
      // console.log("ave5", ave5);

      this.ma5 = ave5[ave5.length - 1].toFixed(2);
      this.ma10 = ave10[ave10.length - 1].toFixed(2);
      this.ma20 = ave20[ave20.length - 1].toFixed(2);

      this.startDate = fullKData[19].lYmd;
      this.endDate = fullKData[79].lYmd;

      //30条里加入均线数据
      const dataCombined = this.combineKAve(
        fullKData.slice(20, 80),
        ave5,
        ave10,
        ave20
      );
      console.log("dataCombined", dataCombined);

      //画k线和量
      drawK({
        id: "d3K",
        data: dataCombined,
        width: $("#d3K").width(),
        ratio: 0.66,
      });
      drawVolume({
        id: "d3Volume",
        data: dataCombined,
        width: $("#d3K").width(),
        ratio: 0.25,
      });
    },

    //获取均线
    getAverageValue: function (num, data) {
      let averageValue = [];
      let totalSum = 0;
      if (data.length <= num) {
        for (let i = 0; i < data.length; i++) {
          totalSum += data[i].fClose;
          averageValue[i] = totalSum / (i + 1);
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          if (i < num) {
            totalSum += data[i].fClose;
            averageValue[i] = totalSum / (i + 1);
          } else {
            totalSum = 0;
            for (let j = i; j > i - num && j > 0; j--) {
              if (i - j < num) {
                totalSum += data[j].fClose;
              }
            }
            averageValue[i] = totalSum / num;
          }
        }
      }
      return averageValue;
    },
    //合并均线数据到k线数据
    combineKAve: function (data, ave5, ave10, ave20) {
      var _data = data;
      for (var i = 0; i < _data.length; i++) {
        _data[i].ave5 = ave5[i];
        _data[i].ave10 = ave10[i];
        _data[i].ave20 = ave20[i];
      }
      return _data;
    },

    moniteAudioEnd() {
      return new Promise((resolve, reject) => {
        console.log( this.$refs["audio" + this.currentCard],this.$refs["audio" + this.currentCard]);
        const moniteHandler = setInterval(async () => {
          if (
            this.$refs["audio" + this.currentCard].currentTime >=
            this.$refs["audio" + this.currentCard].duration - 0.3
          ) {
            await sleep(400);
            clearInterval(moniteHandler);
            resolve();
          }
        }, 100);
      });
    },
    playCard1(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        $("#card1").removeClass("animate__fadeOut");
        this.currentCard = 1;
        this.$refs.audio1.currentTime = 0;
        this.$refs.audio1.play();
        this.playState = true;
        $("#card1").removeClass("none");
        this.rangeValue = 1;
        $("#btnStart").addClass("none");
        await this.moniteAudioEnd(1);
        if (isDebugStop) {
          return;
        }
        $("#card1").addClass("none");
        if (1 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard2(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        $("#card2").removeClass("animate__fadeOut");
        this.currentCard = 2;
        this.$refs.audio2.currentTime = 0;
        this.$refs.audio2.play();
        this.playState = true;
        $("#card2").removeClass("none");
        this.rangeValue = 2;
        this.renderKLine(this.dataK);
        await this.moniteAudioEnd(2);
        if (isDebugStop) {
          return;
        }
        $("#card2").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card2").addClass("none");

        if (2 == this.currentCard) {
          resolve();
        }
        // resolve();
      });
    },

    playCard3(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        $("#card3").removeClass("animate__fadeOut");
        this.currentCard = 3;
        this.$refs.audio3.currentTime = 0;
        this.$refs.audio3.play();
        this.playState = true;
        this.lastTimestamp = this.$refs.audio3.currentTime;
        $("#card3").removeClass("none");
        this.rangeValue = 3;
        await this.moniteAudioEnd(3);
        if (isDebugStop) {
          return;
        }
        await $("#card3").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card3").addClass("none");
        if (3 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard4(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        $("#card4").removeClass("animate__fadeOut");
        this.currentCard = 4;
        this.$refs.audio3.currentTime = 0;
        this.$refs.audio4.play();
        this.playState = true;
        $("#card4").removeClass("none");
        this.rangeValue = 4;
        await this.moniteAudioEnd(4);
        if (isDebugStop) {
          return;
        }
        await $("#card4").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card4").addClass("none");
        if (4 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard5(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        $("#card5").removeClass("animate__fadeOut");
        this.currentCard = 5;
        this.$refs.audio5.currentTime = 0;
        this.$refs.audio5.play();
        this.playState = true;
        $("#card5").removeClass("none");
        this.rangeValue = 5;

        await this.moniteAudioEnd(5);
        if (isDebugStop) {
          return;
        }
        $("#card5").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card5").addClass("none");
        if (5 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard6(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        $("#card6").removeClass("animate__fadeOut");
        this.currentCard = 6;
        this.$refs.audio6.currentTime = 0;
        this.$refs.audio6.play();
        this.playState = true;
        $("#card6").removeClass("none");
        this.rangeValue = 6;
        await this.moniteAudioEnd(6);
        if (isDebugStop) {
          return;
        }
        $("#card6").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card6").addClass("none");
        if (6 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard7(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        $("#card7").removeClass("animate__fadeOut");
        this.currentCard = 7;
        this.$refs.audio7.currentTime = 0;
        this.$refs.audio7.play();
        this.playState = true;
        $("#card7").removeClass("none");
        this.rangeValue = 7;
        await this.moniteAudioEnd(7);
        if (isDebugStop) {
          return;
        }
        $("#card7").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card7").addClass("none");
        if (7 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard8(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        $("#card8").removeClass("animate__fadeOut");
        this.currentCard = 8;
        this.$refs.audio8.currentTime = 0;
        this.$refs.audio8.play();
        this.playState = true;
        $("#card8").removeClass("none");
        this.rangeValue = 8;
        setTimeout(() => {
          $("#ad1").removeClass("none");
        }, 1000);
        await this.moniteAudioEnd(8);
        if (isDebugStop) {
          return;
        }
        $("#card8").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card8").addClass("none");
        if (8 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard9(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        $("#card9").removeClass("animate__fadeOut");
        this.currentCard = 9;
        this.$refs.audio9.currentTime = 0;
        this.$refs.audio9.play();
        this.playState = true;
        $("#card9").removeClass("none");
        this.rangeValue = 9;
        await this.moniteAudioEnd(9);
        if (isDebugStop) {
          return;
        }
        $("#card9").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card9").addClass("none");
        if (9 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard10(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        $("#card10").removeClass("animate__fadeOut");
        this.currentCard = 10;
        this.$refs.audio10.currentTime = 0;
        this.$refs.audio10.play();
        this.playState = true;
        $("#card10").removeClass("none");
        this.rangeValue = 10;
        await this.moniteAudioEnd(10);
        if (isDebugStop) {
          return;
        }
        $("#card10").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card10").addClass("none");
        if (10 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard11(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        $("#card11").removeClass("animate__fadeOut");
        this.currentCard = 11;
        this.$refs.audio11.currentTime = 0;
        this.$refs.audio11.play();
        this.playState = true;
        $("#card11").removeClass("none");
           this.rangeValue = 11;
            await this.moniteAudioEnd(11);
        if (isDebugStop) {
          return;
        }
        this.reStartState = true;
        if (11 == this.currentCard) {
          this.playState = false;
          resolve();
        }
      });
    },

    // 转场的时候不支持暂停播放
    play() {
      if (this.currentCard < 12) {
        if (
          this.$refs["audio" + this.currentCard].currentTime <
          this.$refs["audio" + this.currentCard].duration - 0.3
        ) {
          this.playAudio();
          this.playState = true;
        }
      }
    },
    stop() {
      if (this.currentCard < 12) {
        if (
          this.$refs["audio" + this.currentCard].currentTime <
          this.$refs["audio" + this.currentCard].duration - 0.3
        ) {
          this.stopAudio();
          this.playState = false;
        }
      }
    },
    async playBgAudio() {
      this.$refs.bgAudio.play(); //背景音乐播放
      await sleep(500);
      this.$refs.bgAudio.volume = 0.1; //背景音乐音量调节（1为最大）
    },
    //开始播放
    async startPlay() {
      if (!this.isFullLoaded) {
        return;
      }
      this.playBgAudio();
      this.progessState = true;
      await this.playCard1();
      await this.playCard2();
      await this.playCard3();
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
      this.reStartState = true;
    },
    async play1() {
      this.$refs.audio1.currentTime = 0;
      await this.playCard1();
      await this.playCard2();
      await this.playCard3();
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play2() {
      this.$refs.audio2.currentTime = 0;
      await this.playCard2();
      await this.playCard3();
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play3() {
      this.$refs.audio3.currentTime = 0;
      await this.playCard3();
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play4() {
      this.$refs.audio4.currentTime = 0;
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play5() {
      this.$refs.audio5.currentTime = 0;
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play6() {
      this.$refs.audio6.currentTime = 0;
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play7() {
      this.$refs.audio7.currentTime = 0;
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play8() {
      this.$refs.audio8.currentTime = 0;
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play9() {
      this.$refs.audio9.currentTime = 0;
      await this.playCard9();
      await this.playCard10();
      await this.playCard11();
    },
    async play10() {
      this.$refs.audio10.currentTime = 0;
      await this.playCard10();
      await this.playCard11();
    },

    // 重播
    async reStart() {
      this.clearFadeOut();
      this.reStartState = false;
      this.currentCard = 1;
      $("#card11").addClass("none");
      this.startPlay();
    },
    // 清除每个card播放之后的animate__fadeOut
    clearFadeOut() {
      $("#card1").removeClass("animate__fadeOut");
      $("#card2").removeClass("animate__fadeOut");
      $("#card3").removeClass("animate__fadeOut");
      $("#card4").removeClass("animate__fadeOut");
      $("#card5").removeClass("animate__fadeOut");
      $("#card6").removeClass("animate__fadeOut");
      $("#card7").removeClass("animate__fadeOut");
      $("#card8").removeClass("animate__fadeOut");
      $("#card9").removeClass("animate__fadeOut");
      $("#card10").removeClass("animate__fadeOut");
      $("#card11").removeClass("animate__fadeOut");
    },
    // rang进度条change
    changeRange() {
      this.clearFadeOut();
      console.log(this.currentCard, this.$refs["audio" + this.currentCard]);
      if (this.currentCard < 12) {
        this.stopAudio();
      }
      let page = "#card" + this.currentCard;
      $(page).addClass("none");
      const range = document.getElementById("range");
      if (range.value == 1) {
        this.play1();
      }
      if (range.value == 2) {
        this.play2();
      }
      if (range.value == 3) {
        this.play3();
      }
      if (range.value == 4) {
        this.play4();
      }
      if (range.value == 5) {
        this.play5();
      }
      if (range.value == 6) {
        this.play6();
      }
      if (range.value == 7) {
        this.play7();
      }
      if (range.value == 8) {
        this.play8();
      }
      if (range.value == 9) {
        this.play9();
      }
      if (range.value == 10) {
        this.play10();
      }
      if (range.value == 11) {
        this.playCard11();
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "./Template57.less";
</style>
